import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { PostCommentPlaceholder } from '@components/PostCommentPlaceholder';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "postcommentplaceholder"
    }}>{`PostCommentPlaceholder`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={PostCommentPlaceholder} mdxType="Props" />
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={1} __code={'<PostCommentPlaceholder />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      PostCommentPlaceholder,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PostCommentPlaceholder mdxType="PostCommentPlaceholder" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      